class Header
    constructor: (el) ->
        @.$el = el
        @.$menuButton = @$el.querySelector '.header--menu-button'

        @.initEvents()
        @.setBinded()

    initEvents: ->
        self = @
        @$menuButton.addEventListener 'click', (e) ->
            self.$el.classList.toggle 'header--menu-active'
    
    setBinded: ->
        @$el.classList.add 'binded'
    
document.querySelectorAll '.js-header:not(.binded)'
    .forEach (el) ->
        new Header(el)